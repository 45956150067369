// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"
import { isMobile } from "react-device-detect"

// Utils
import { loadAssets, animateSprites } from "../../utils/canvas"

// Components
import SEO from "../../components/seo"
import { drawImages } from "../../components/illustration/images"
import PageComponents from "../../components/pageComponents"

// Style
import sharedStyles from "./../pages.module.scss"

// Assets
import bgImg from "./assets/bg.jpg"
import mgImg from "./assets/mg.png"
import fgImg from "./assets/fg.png"
import appleImg from "./assets/apple.png"
import birdImg from "./assets/bird.png"
import bushMaskImg from "./assets/bush-mask.png"
import cat1Img from "./assets/cat-1.png"
import cat2Img from "./assets/cat-2.png"
import dogImg from "./assets/dog.png"
import tvBottomImg from "./assets/tv-bottom.png"
import robodogImg from "./assets/robodog.png"
import tailImg from "./assets/tail.png"

// Sprites
import droneImg from "./assets/sprites/drone.png"
import solarImg from "./assets/sprites/solar.png"
import heatImg from "./assets/sprites/heat.png"
import graphImg from "./assets/sprites/graph.png"

// Audio
import ambient from "./assets/audio/ambient.mp3"
import vo from "./assets/audio/vo.mp3"

const pageData = {
  index: 8,
  preHeading: "Our Home",
  heading: "This must be the place we call home.",
  content:
    "Where we are proud of the path we’ve taken and grateful of the gift we give to future generations. It’s where we still shudder to think back on striking students, wildfires and waters rising. We still well up to think “what if we’d been found wanting”. But here we said enough is enough. We choose to be custodians. Here, we pass down our compassion and weigh our worth by the world we pass on with it. Here, we look to our children and can smile face to face. Because we decided, no, here and now, this must be the place.",
  slug: "our-home",
  nextSlug: "credits",
}

const OurHome = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  // Asset setup
  const bg = useRef({
      parallax: 0.2,
      src: bgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    mg = useRef({
      parallax: 0.5,
      src: mgImg,
      el: null,
      offset: { x: -80, y: 0 },
    }),
    fg = useRef({
      parallax: 1,
      src: fgImg,
      el: null,
      offset: { x: 850, y: 450 },
    }),
    apple1 = useRef({
      parallax: 0.2,
      src: appleImg,
      el: null,
      offset: { x: 3337, y: 424 },
    }),
    apple2 = useRef({
      parallax: 0.2,
      src: appleImg,
      el: null,
      offset: { x: 3717, y: 644, r: 0 },
      rotationPoint: { x: 37, y: 37 },
    }),
    apple3 = useRef({
      parallax: 0.2,
      src: appleImg,
      el: null,
      offset: { x: 3739, y: 913, r: 0 },
      rotationPoint: { x: 37, y: 37 },
    }),
    apple4 = useRef({
      parallax: 0.2,
      src: appleImg,
      el: null,
      offset: { x: 3486, y: 723, r: 0 },
      displaySize: { w: 58, h: 58 },
      rotationPoint: { x: 29, y: 29 },
    }),
    bird = useRef({
      parallax: 0.2,
      src: birdImg,
      el: null,
      offset: { x: 3450, y: 455 },
    }),
    bushMask = useRef({
      parallax: 0.2,
      src: bushMaskImg,
      el: null,
      offset: { x: 3161, y: 482 },
    }),
    drone = useRef({
      parallax: 0.2,
      src: droneImg,
      el: null,
      offset: { x: 3361, y: 1341 },
      size: { w: 160, h: 200 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    graph = useRef({
      parallax: 0.5,
      src: graphImg,
      el: null,
      offset: { x: 740, y: 856 },
      size: { w: 140, h: 96 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 25,
      loop: true,
    }),
    solar = useRef({
      parallax: 0.5,
      src: solarImg,
      el: null,
      offset: { x: 726, y: 634 },
      size: { w: 170, h: 170 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 5,
      loop: true,
    }),
    heat = useRef({
      parallax: 0.5,
      src: heatImg,
      el: null,
      offset: { x: 934, y: 634 },
      size: { w: 170, h: 170 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 4,
      loop: true,
    }),
    cat1 = useRef({
      parallax: 0.5,
      src: cat1Img,
      el: null,
      offset: { x: 1354, y: 786 },
    }),
    cat2 = useRef({
      parallax: 0.5,
      src: cat2Img,
      el: null,
      offset: { x: 1354, y: 776 },
      hidden: true,
    }),
    dog = useRef({
      parallax: 0.5,
      src: dogImg,
      el: null,
      offset: { x: 1820, y: 958 },
    }),
    tvBottom = useRef({
      parallax: 0.5,
      src: tvBottomImg,
      el: null,
      offset: { x: 1809, y: 944 },
    }),
    robodog = useRef({
      parallax: 1,
      src: robodogImg,
      el: null,
      offset: { x: 1343, y: 1425 },
    }),
    tail = useRef({
      parallax: 1,
      src: tailImg,
      el: null,
      offset: { x: 1670, y: 1753, r: 12 },
      rotationPoint: { x: 19, y: 122 },
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    bg.current,
    bird.current,
    bushMask.current,
    drone.current,
    apple1.current,
    apple2.current,
    apple3.current,
    apple4.current,
    mg.current,
    solar.current,
    graph.current,
    heat.current,
    cat1.current,
    cat2.current,
    dog.current,
    tvBottom.current,
    fg.current,
    robodog.current,
    tail.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([
    drone.current,
    solar.current,
    graph.current,
    heat.current,
  ])

  // Set the mouse entry point if there is one passed in
  const mousePoint = {
    x: entry.state.mousePos ? entry.state.mousePos.x : 0,
    y: entry.state.mousePos ? entry.state.mousePos.y : 0,
  }

  // The point to focus on when the page enters
  const focusPoint = {
    x: 1160,
    y: 1528,
  }

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  const showDog = direction => {
    if (direction === "in") {
      cat1.current.hidden = true
      cat2.current.hidden = false
      gsap.to(dog.current.offset, { y: 750, duration: 0.6, ease: "power4.out" })
    } else {
      cat1.current.hidden = false
      cat2.current.hidden = true
      gsap.to(dog.current.offset, { y: 958, duration: 1, ease: "power1.out" })
    }
  }

  const showBird = direction => {
    if (direction === "in") {
      if (appleWobble.current) appleWobble.current.kill()
      gsap.to(bird.current.offset, {
        x: 3423,
        y: 424,
        duration: 0.3,
        ease: "power3.out",
      })
      gsap.to(apple1.current.offset, {
        x: 3403,
        y: 1310,
        duration: 1,
        ease: "power2.in",
      })
      gsap.to(apple1.current.offset, {
        y: 1300,
        duration: 0.1,
        ease: "power1.in",
        repeat: -1,
        yoyo: true,
        delay: 1,
      })
      // gsap.to(apple1.current.offset, {x: 2967, duration: 1, ease: "power1.out", delay: 1})
      // gsap.to(apple1.current.offset, {r: -1160, duration: 2.2, ease: "power1.out", delay: 1})
      // gsap.to(apple1.current.offset, {y: 1754, duration: 2, ease: "bounce.out", delay: 1})
    }
  }

  let appleWobble = useRef(null)

  // Initial animations
  const startingAnimations = () => {
    appleWobble.current = gsap.fromTo(
      apple1.current.offset,
      { y: 424 },
      { y: 428, duration: 0.8, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      cat2.current.offset,
      { y: 776 },
      { y: 774, duration: 0.05, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
    // gsap.fromTo(tail.current.offset, {r: 12}, {r: -12, duration: .6, ease: "power1.inOut", repeat:-1, yoyo:true})
    gsap.fromTo(
      tail.current.offset,
      { r: 12 },
      { r: -20, duration: 1, ease: "steps(4)", repeat: -1, yoyo: true }
    )
  }

  // Animate in
  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
      startingAnimations()
      if (isMobile) showBird("in")
    }, 1000)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
    }
  }, [])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div>
      <SEO title={pageData.heading} />
      <div className={sharedStyles.page}>
        <PageComponents
          index={pageData.index}
          preHeading={pageData.preHeading}
          heading={pageData.heading}
          content={pageData.content}
          mousePoint={mousePoint}
          focusPoint={focusPoint}
          playing={reveal}
          draw={draw}
          fromPage={pageData.slug}
          to={`/${pageData.nextSlug}`}
          ambient={ambient}
          vo={vo}
          interactiveElements={[
            {
              event: showBird,
              x: 3240,
              y: 350,
              w: 510,
              h: 440,
            },
            {
              event: showDog,
              x: 1345,
              y: 433,
              w: 1150,
              h: 650,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default OurHome
